"use client";

import Link from "next/link";
import React, { useState, useEffect } from "react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import Image from "next/image";
import CartIcon from "../../../public/icons/shopping-cart-line.svg";
import { Button } from "../ui/button";
import { getAccessToken, removeTokens } from "@/utils/auth";
import { useRouter } from "next/navigation";
import { HeaderSectionContent } from "@/constants";
import { Menu } from "lucide-react";
import SearchBar from "../search/SearchBar";
import { useCart } from "@/context/CartContext";

const HeaderSection = () => {
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const router = useRouter();
  const { cartItems } = useCart();

  useEffect(() => {
    const token = getAccessToken();
    if (token) {
      setIsRegistered(true);
    }
  }, []);

  // Add scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const LogOut = async () => {
    try {
      if (typeof window !== "undefined") {
        removeTokens();
        router.push("/account");

        setTimeout(() => {
          window.location.reload();
        }, 1200);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <header
      className={`w-full sticky top-0 left-0 bg-[#F7553E] z-50 transition-all duration-300`}
    >
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16 sm:h-20">
          <div className="flex items-center gap-5">
            <Link href="/" className="transition-transform hover:scale-105">
              <Image src="/images/Logo.png" alt="Logo" width={50} height={50} />
            </Link>
            <nav className="hidden text-white md:flex items-center gap-5">
              {HeaderSectionContent.map((link, key) => (
                <Link
                  href={link.redirect}
                  key={key}
                  className={`text-sm font-medium `}
                >
                  {link.title}
                </Link>
              ))}
            </nav>
          </div>

          <div className="hidden md:flex items-center gap-4">
            <SearchBar />

            {isRegistered ? (
              <div className="flex items-center gap-3">
                <Button
                  className="py-2 px-4 text-sm bg-transparent text-black border border-black/50 hover:bg-black hover:text-white hover:border-transparent 
                  font-HeroNew font-bold rounded-full transition-all duration-300 hover:scale-105 active:scale-95"
                  onClick={() => router.push("/profile")}
                >
                  <Image
                    src="/icons/user-line.png"
                    alt=""
                    width={15}
                    height={15}
                    className="mr-2 transition-transform group-hover:scale-110"
                  />
                  Profile
                </Button>
                <Button
                  className="py-2 px-4 text-sm bg-transparent text-black border border-black/50 hover:bg-black hover:text-white hover:border-transparent 
                  font-HeroNew font-bold rounded-full transition-all duration-300 hover:scale-105 active:scale-95"
                  onClick={LogOut}
                >
                  Log out
                </Button>
                <Link href="/cart" className="relative group">
                  <Button
                    className="p-2 bg-transparent hover:bg-gray-100/80 text-black transition-all duration-300 group-hover:scale-105"
                    variant="ghost"
                  >
                    <Image src={CartIcon} alt="Cart" width={60} height={60} />
                    {cartItems && cartItems.length > 0 && (
                      <span
                        className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center
                    animate-pulse group-hover:animate-none"
                      >
                        {cartItems.length}
                      </span>
                    )}
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="flex items-center gap-3">
                <Link href="/account">
                  <Button
                    className="py-2 px-4 text-sm bg-transparent bg-white text-black border  hover:bg-[#101010] hover:text-white 
                    hover:border-transparent font-HeroNew font-bold rounded-full transition-all duration-300 hover:scale-105 active:scale-95"
                  >
                    Register
                  </Button>
                </Link>
                <Link href="/account">
                  <Button
                    className="py-2 px-4 text-sm bg-white hover:bg-[#101010] font-HeroNew font-bold rounded-full text-black 
                    transition-all hover:text-white duration-300 hover:scale-105 active:scale-95 hover:shadow-md"
                  >
                    Sign in
                  </Button>
                </Link>
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 md:hidden">
            <Link href="/cart" className="relative group">
              <Button
                className="p-2 bg-transparent hover:bg-gray-100 text-black transition-all duration-300 group-hover:scale-105"
                variant="ghost"
              >
                <Image src={CartIcon} alt="Cart" width={24} height={24} />
                {cartItems && cartItems.length > 0 && (
                  <span
                    className="absolute -top-1 -right-1 bg-[#F7553E] text-white text-xs w-5 h-5 rounded-full flex items-center justify-center
                    animate-pulse group-hover:animate-none"
                  >
                    {cartItems.length}
                  </span>
                )}
              </Button>
            </Link>
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="hover:bg-gray-100/80 transition-all duration-300"
                >
                  <Menu className="h-6 w-6 transition-transform hover:scale-110" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent
                side="right"
                className="w-[300px] sm:w-[400px] backdrop-blur-md bg-white/95"
              >
                <nav className="flex flex-col gap-4 mt-8">
                  <SearchBar />
                  {HeaderSectionContent.map((link, key) => (
                    <Link
                      href={link.redirect}
                      key={key}
                      className="text-lg font-bold font-HeroNew hover:text-[#F7553E] transition-colors"
                      target={link.target}
                    >
                      {link.title}
                    </Link>
                  ))}
                  {isRegistered ? (
                    <>
                      <Button
                        className="w-full py-3 px-3 text-sm bg-transparent flex-center gap-2 text-black border-[1px] border-black/50 hover:bg-[#F7553E] hover:text-white hover:border-none 
                       font-HeroNew font-bold rounded-full hero-button duration-300"
                        onClick={() => router.push("/profile")}
                      >
                        <Image
                          src="/icons/user-line.png"
                          alt=""
                          width={20}
                          height={20}
                        />
                        Profile
                      </Button>
                      <Button
                        className="w-full py-3 px-3 text-xs bg-white hover:bg-black font-HeroNew font-bold rounded-full hero-button"
                        onClick={LogOut}
                      >
                        Log out
                      </Button>
                    </>
                  ) : (
                    <>
                      <Link href="/account" className="w-full">
                        <Button className="w-full py-3 px-3 text-lg bg-transparent text-black border-[1px] border-black/50 hover:bg-[#F7553E] hover:text-white hover:border-none font-HeroNew font-bold rounded-full hero-button duration-300">
                          Register
                        </Button>
                      </Link>
                      <Link href="/account" className="w-full">
                        <Button className="w-full py-3 px-3 text-lg bg-white hover:bg-black font-HeroNew font-bold rounded-full hero-button">
                          Sign in
                        </Button>
                      </Link>
                    </>
                  )}
                </nav>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderSection;
