"use client";

import Image from "next/image";
import React from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import FacebookIcon from "../../../public/icons/facebook-circle-line.png";
import InstagramIcon from "../../../public/icons/instagram-line.png";
import WhatsappIcon from "../../../public/icons/whatsapp-line.png";
import { FooterSectionContentTwo, FooterSectionCotent } from "@/constants";
import {
  ArrowUp,
  Copyright,
  Facebook,
  Instagram,
  MapPin,
  Phone,
} from "lucide-react";

export default function FooterSection() {
  const router = useRouter();

  // Sample data for preview
  const address = "CMPF Colony, Dhanbad, Jharkhand 826001";
  const phoneNumber = "9088008328";

  return (
    <footer className="mt-6 w-full bg-gray-50">
      <div className="max-w-[1440px] mx-auto px-6 py-16 sm:px-8 lg:px-12">
        <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
          <div className="space-y-8 lg:col-span-1">
            <Image
              src="/images/Logo.png"
              alt="Maruti Bakers Mart Logo"
              width={112}
              height={40}
              className="w-36 object-contain"
            />
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <MapPin className="h-5 w-5 text-gray-600 mt-1 flex-shrink-0" />
                <p className="text-gray-600 text-sm">{address}</p>
              </div>
              <div className="flex items-center space-x-3">
                <Phone className="h-5 w-5 text-gray-600 flex-shrink-0" />
                <p className="text-gray-600 text-sm font-bold">{phoneNumber}</p>
              </div>
            </div>
            <div className="flex space-x-6">
            <a href="https://www.facebook.com/marutibakersmart/" className="hover:opacity-75 transition-opacity" target="_blank">
                <Image src={FacebookIcon} alt="Facebook" className="w-5 h-5" />
              </a>
              <a href="https://www.instagram.com/marutibakersmart/" className="hover:opacity-75 transition-opacity" target="_blank">
                <Image src={InstagramIcon} alt="Instagram" className="w-5 h-5" />
              </a>
              <a href="https://wa.me/9088008328" className="hover:opacity-75 transition-opacity" target="_blank">
                <Image src={WhatsappIcon} alt="WhatsApp" className="w-5 h-5" />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-bold text-gray-900 mb-6">
              Quick Links
            </h3>
            <ul className="space-y-4">
              {FooterSectionCotent.map((link: any, index: any) => (
                <li key={index}>
                  <Link
                    href={link.redirect}
                    className="text-gray-600 hover:text-gray-900 hover:translate-x-1 transition-all duration-300 flex items-center text-sm font-medium"
                  >
                    <span className="hover:underline">{link.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold text-gray-900 mb-6">More Info</h3>
            <ul className="space-y-4">
              {FooterSectionContentTwo.map((link, index) => (
                <li key={index}>
                  <Link
                    href={link.redirect}
                    className="text-gray-600 hover:text-gray-900 hover:translate-x-1 transition-all duration-300 flex items-center text-sm font-medium"
                  >
                    <span className="hover:underline">{link.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="space-y-6">
            <h3 className="text-lg font-bold text-gray-900 mb-6">
              Stay Connected
            </h3>
            <button
              onClick={() => router.push("/")}
              className="group inline-flex items-center justify-center p-3 rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300 hover:shadow-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <ArrowUp className="h-6 w-6 transform group-hover:-translate-y-1 transition-transform duration-300" />
              <span className="sr-only">Scroll to top</span>
            </button>
          </div>
        </div>
        <div className="mt-16 pt-8 border-t border-gray-200">
          <p className="text-gray-500 text-center flex items-center justify-center text-sm">
            <Copyright className="h-4 w-4 mr-2" />
            <span>
              {new Date().getFullYear()} Maruti Bakers Mart. All rights
              reserved.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
}
