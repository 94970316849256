export const HeaderSectionContent = [
  {
    title: "HOME",
    redirect: "/"
  },

  {
    title: "SHOP",
    redirect: "/shop"
  },

  {
    title: "CATEGORIES",
    redirect: "/categories"
  },

  {
    title: "BEST SELLER",
    redirect: "/#best-seller"
  },

  {
    title: "ABOUT US",
    redirect: "https://www.marutibakersmart.com/#about",
    target: "_blank"
  },
];
export const HeaderContentIcon = [
  {
    ImageUrl: "/icons/user-line.png",
    redirect: "https://www.marutibakersmart.com/profile"
  },

  {
    ImageUrl: "/icons/shopping-cart-line.png",
    redirect: "https://www.marutibakersmart/cart"
  }
];

export const DetailsCardsContent = [
  {
    imageUrl: "/icons/bard-fill.png",
    title: "Years Experience",
    Description: "50"
  },

  {
    imageUrl: "/icons/user-3-fill.png",
    title: "Skilled Professionals",
    Description: "175"
  },

  {
    imageUrl: "/icons/bread-fill.png",
    title: "Total Products",
    Description: "135"
  },

  {
    imageUrl: "/icons/shopping-cart-2-fill.png",
    title: "Order Everyday",
    Description: "50"
  }
];

export const OurBrandslogos = [
  {
    imageUrl: "/images/clients2.webp",
    title: ""
  },

  {
    imageUrl: "/images/clients3.webp",
    title: ""
  },

  {
    imageUrl: "/images/clients6.webp",
    title: ""
  },

  {
    imageUrl: "/images/clients7.webp",
    title: ""
  },

  {
    imageUrl: "/images/clients12.webp",
    title: ""
  },

  {
    imageUrl: "/images/clients19.webp",
    title: ""
  }
];

export const FooterSectionCotent = [
  {
    title: "ABOUT",
    redirect: "https://marutibakersmart.com/#about"
  },

  {
    title: "FAQ",
    redirect: "https://marutibakersmart.com/#faq"
  },
  {
    title: "PRIVACY POLICY",
    redirect: "https://marutibakersmart.com/privacy-policy"
  },

  {
    title: "TERMS AND CONDTIONS",
    redirect: "https://marutibakersmart.com/terms-and-condition"
  },

  {
    title: "CANCELLATION AND REFUND",
    redirect: "https://marutibakersmart.com/cancellation-and-refund"
  },

  {
    title: "SHIPPING AND DELIVERY",
    redirect: "https://marutibakersmart.com/shipping-and-delivery"
  },

  {
    title: "CONTACT US",
    redirect: "https://marutibakersmart.com/contact-us"
  }
];

export const FooterSectionContentTwo = [
  {
    title: "Home",
    redirect: "/"
  },
  {
    title: "Shop",
    redirect: "/shop"
  },
  {
    title: "Category",
    redirect: "/categories"
  },
  {
    title: "Foods",
    redirect: "/shop"
  }
];

export const FAQSectionContent = [
  {
    question: "What products does Maruti Baker's Mart offer?",
    answer:
      "We offer a wide range of ingredients and supplies for baking enthusiasts and professionals alike. Our product lineup includes essential cake-making ingredients like flour, sugar, baking mixes, chocolate, and decorations, as well as gourmet items. Additionally, we have a distributorship for popular brands such as Nestle, HUL, RICH, Malas, Catch Masala, and more."
  },
  {
    question: "Do you deliver to my location?",
    answer:
      "We currently deliver to various locations across the country. To check if we deliver to your area, simply enter your delivery address or zip code during checkout. Our goal is to expand our delivery network, so if we don’t reach you yet, stay tuned!"
  },

  {
    question: ". How can I place an order?",
    answer:
      "Ordering from Maruti Baker's Mart is quick and easy! Browse our wide range of products, add your favorites to the cart, and proceed to checkout. You can select from various payment options and delivery times that suit your schedule. Once your order is confirmed, you'll receive an email with tracking details."
  },

  {
    question: "Can I customize orders for special occasions?",
    answer:
      "While we don’t sell finished baked goods, we do offer a variety of ingredients and tools that will help you create customized cakes, cupcakes, and other baked goods for special occasions. If you have specific ingredient requirements or need bulk orders, feel free to contact us directly."
  },

  {
    question: "What is your return policy?",
    answer:
      "As most of our products are perishable, we do not accept returns. However, if you are not satisfied with your purchase or there’s an issue with your order, please reach out to us within 24 hours of delivery. Our customer support team will be happy to assist and resolve the matter promptly"
  },

  {
    question: " How do I track my order?",
    answer:
      "Once your order has been placed, you’ll receive a confirmation email with tracking information. You can also log into your account on our website to view the status of your order in real time."
  }
];
