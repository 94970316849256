"use client";
import { useRef, useEffect } from "react";
import { Provider } from "react-redux";
import { WhitelistState } from "../WhiteList";
import store, { RootState } from "../Store";

export default function StoreProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const storeRef = useRef(store);

  useEffect(() => {
    const initialState = localStorage.getItem("reduxState");
    const preloadedState: { whitelist: WhitelistState } = initialState
      ? JSON.parse(initialState)
      : {
          whitelist: { items: [] }
        };
    storeRef.current = store;
    storeRef.current.subscribe(() => {
      const state = storeRef.current.getState() as RootState;
      const whitelistState: WhitelistState = state.whitelist;
      localStorage.setItem(
        "reduxState",
        JSON.stringify({ whitelist: whitelistState })
      );
    });

    storeRef.current.dispatch({
      type: "whitelist/hydrateWhitelist",
      payload: preloadedState.whitelist
    });
  }, []);

  return (
    <Provider store={storeRef.current}>
      {children}
    </Provider>
  );
}
