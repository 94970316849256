import { configureStore } from "@reduxjs/toolkit";
import whitelistSlice from "./WhiteList";

const store = configureStore({
  reducer: {
    whitelist: whitelistSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;   