'use client'
import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

interface CartItem {
  id: string;
  productId: string;
  quantity: number;
  product: {
    id: string;
    name: string;
    price: number;
    images: { url: string }[];
  };
}

interface CartContextType {
  cartItems: CartItem[];
  addToCart: (productId: string | number, quantity?: number) => Promise<void>;
  removeFromCart: (productId: string) => Promise<void>;
  isLoading: boolean;
}

export const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch cart on mount and when token changes
  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    try {
      const token = Cookies.get('accessToken');
      if (!token) return;

      const response = await axios.get('https://api.marutibakersmart.com/v1/cart/get-cart', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Assuming the API returns an array of cart items in response.data[0].items
      const items = response.data[0]?.items || [];
      setCartItems(items);
    } catch (error) {
      console.error('Error fetching cart:', error);
      toast.error('Failed to fetch cart items');
    }
  };

  const addToCart = async (productId: string | number, quantity: number = 1) => {
    setIsLoading(true);
    try {
      const token = Cookies.get('accessToken');
      if (!token) {
        toast.error('Please login to add items to cart');
        return;
      }
 
      // Convert number productId to string if necessary
      const stringProductId = productId.toString();

      await axios.post(
        'https://api.marutibakersmart.com/v1/cart/add-cart',
        { productId: stringProductId, quantity },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      await fetchCart(); // Refresh cart after adding
      toast.success('Product added to cart');
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add product to cart');
    } finally {
      setIsLoading(false);
    }
  };

  const removeFromCart = async (productId: string) => {
    setIsLoading(true);
    try {
      const token = Cookies.get('accessToken');
      if (!token) {
        toast.error('Please login to remove items from cart');
        return;
      }

      await axios.delete('https://api.marutibakersmart.com/v1/cart/delete-cart', {
        headers: { Authorization: `Bearer ${token}` },
        data: { productId }
      });

      await fetchCart(); // Refresh cart after removing
      toast.success('Product removed from cart');
    } catch (error) {
      console.error('Error removing from cart:', error);
      toast.error('Failed to remove product from cart');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CartContext.Provider value={{ 
      cartItems, 
      addToCart, 
      removeFromCart, 
      isLoading 
    }}>
      {children}
    </CartContext.Provider>
  );
};

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
} 