import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WhitelistItem {
  id: string;
}

export interface WhitelistState {
  items: WhitelistItem[];
}

const initialState: WhitelistState = {
  items: [],
};

const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    addToWhitelist(state, action: PayloadAction<{ id: string; name: string; email: string }>) {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      if (!existingItem) {
        state.items.push({ ...newItem });
      }
      
    },
    removeFromWhitelist(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.items = state.items.filter((item) => item.id !== id);
    },
    hydrateWhitelist(state, action: PayloadAction<WhitelistState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { addToWhitelist, removeFromWhitelist, hydrateWhitelist } = whitelistSlice.actions;

export default whitelistSlice.reducer;