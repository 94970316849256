import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/core/FooterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/core/HeaderSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/app/src/context/CartContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/redux/provider/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
