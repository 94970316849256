'use client'

import React, { useState, useEffect, useRef } from "react"
import { Input } from "@/components/ui/input"
import { Search, Loader2 } from 'lucide-react'
import { useDebounce } from "@/hooks/useDebounce"
import axios from "axios"
import Link from "next/link"
import { cn } from "@/lib/utils"

interface Product {
  id: string
  name: string
  description: string
  summary: string
  price: number
  fake_price: number
  bulletpoints: string
  origin: string
  stock: number
  modal: string
  product_Weight: string
  length: string
  width: string
  height: string
  categoryId: string
  subCategoryId: string | null
  createdAt: string
  updatedAt: string
  specialMonthlyProduct: boolean
  images: Array<{ id: string; url: string }>
}

interface SearchResponse {
  products: Product[]
}

const SearchBar = () => {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const debouncedQuery = useDebounce(query, 300)
  const searchRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsFocused(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const searchProducts = async () => {
      if (debouncedQuery.length < 2) {
        setResults([])
        return
      }

      setIsLoading(true)
      try {
        const response = await axios.get<SearchResponse>(
          `https://api.marutibakersmart.com/v1/search/search-products?query=${debouncedQuery}`
        )
        setResults(response.data.products)
      } catch (error) {
        console.error("Error fetching search results:", error)
        setResults([])
      } finally {
        setIsLoading(false)
      }
    }

    searchProducts()
  }, [debouncedQuery])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Implement search submission logic here
    console.log("Search submitted:", query)
  }

  return (
    <div className="relative w-full max-w-[300px]" ref={searchRef}>
      <form onSubmit={handleSubmit} className="relative">
        <Input
          type="text"
          placeholder="Search products..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={handleFocus}
          ref={inputRef}
          className={cn(
            "w-full pr-10 rounded-md text-gray-900 bg-white border-2 border-black",
            "focus:black focus:ring-2 focus:ring-orange-200 focus:outline-none",
            "placeholder-gray-400 transition-all duration-200 ease-in-out"
          )}
        />
        <button
          type="submit"
          className="absolute right-0 top-0 h-full px-3 text-gray-500 hover:text-black transition-colors duration-200"
          aria-label="Search"
        >
          <Search className="h-5 w-5" />
        </button>
      </form>
      {isFocused && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-96 overflow-y-auto border border-gray-200">
          {isLoading ? (
            <div className="p-4 text-center text-gray-500 flex items-center justify-center">
              <Loader2 className="h-5 w-5 animate-spin mr-2" />
              Loading...
            </div>
          ) : results.length > 0 ? (
            results.map((product) => (
              <Link
                key={product.id}
                href={`/product/${product.id}`}
                className="flex items-center p-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
              >
                <img
                  src={product.images[0]?.url || "/placeholder-image.jpg"}
                  alt={product.name}
                  className="w-16 h-16 object-cover rounded-md mr-3"
                />
                <div className="flex-grow">
                  <p className="font-semibold text-gray-800 line-clamp-1">{product.name}</p>
                  <p className="text-sm text-gray-600">${product.price.toFixed(2)}</p>
                  <p className="text-xs text-gray-500 line-clamp-1">{product.origin}</p>
                </div>
              </Link>
            ))
          ) : (
            <div className="p-4 text-center text-gray-500">
              {query.length < 2 ? "Type to search" : "No results found"}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchBar

